<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <label>{{ $t('system.pm_role') }}</label>
        <el-select
            @change="getTranitionsTable()"
            filterable
            multiple
            placeholder=""
            v-model="choosedRoles"
        >
          <el-option
              :key="id"
              :label="name"
              :value="id"
              v-for="(name, id) in pmRoles"
          >
          </el-option>
        </el-select>
        <label class="ml-4">{{ $t('json.tracker_id') }}</label>
        <el-select
            @change="getTranitionsTable()"
            filterable
            multiple
            placeholder=""
            v-model="choosedTrackers"
        >
          <el-option
              :key="item.id"
              :label="item.name"
              :value="item.id"
              v-for="item in trackers"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div
        class="view"
        v-if="showTable"
    >
      <div class="view-body">
        <table
            border
            class="table workflow-table mb-0"
            fit
            highlight-current-row
            style="width: 100%;"
        >
          <thead class="has-gutter">
          <tr class="">
            <th>{{ $t('system.current_status') }}</th>
            <th :colspan="statuses.length">{{ $t('system.new_stat_allowed') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="">
            <td></td>
            <td
                v-bind:key="status.id"
                v-for="status in statuses"
            >
              <el-button @click="toogleColumn(status.id)">{{ status.name }}</el-button>
            </td>
          </tr>
          <tr
              class=""
              v-bind:key="status.id"
              v-for="status in statuses"
          >
            <td>
              <el-button @click="toogleRow(status.id)">{{ status.name }}</el-button>
            </td>
            <td
                v-bind:key="stat.id"
                v-for="stat in statuses"
            >
              <el-select
                  filterable
                  v-if="getValue(status.id, stat.id) == 'dk'"
                  v-model="checks[status.id + '_' + stat.id]"
              >
                <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    v-for="item in options"
                >
                </el-option>
              </el-select>

              <el-checkbox
                  v-if="(getValue(status.id, stat.id) == true) || (getValue(status.id, stat.id) == false)"
                  v-model="checks[status.id + '_' + stat.id]"
              >
              </el-checkbox>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!--</div>-->
      <!--<div class="view">-->
      <div class="box-button align-right pt-0">
        <el-button
            @click="saveWorkflow"
            type="primary"
        >{{ $t('system.save') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import notification from '../../notification/notify';

export default {
  name: 'WorkflowIndex',
  data() {
    return {
      trackers: [],
      statuses: [],
      checks: {},
      pmRoles: [],
      choosedRoles: [],
      choosedTrackers: [],
      model: [],
      options: [
        {
          value: true,
          label: this.$t('system.yes'),
          key: true,
        },
        {
          value: false,
          label: this.$t('system.no'),
          key: false,
        },
        {
          value: 'dk',
          label: this.$t('system.no_change'),
          key: 'dk',
        },
      ],
      showTable: false,
    };
  },
  beforeMount: function () {
    this.getTrackers();
    this.getStatuses();
    this.getPmRoles();
  },
  methods: {
    toogleRow(id) {
      let keys = [];
      let vm = this;
      _.forEach(this.checks, function (value, key) {
        if (key.startsWith(id + '_')) {
          keys.push(key);
        }
      });
      _.forEach(keys, function (value, key) {
        vm.checks[value] = !vm.checks[value];
      });
    },
    toogleColumn(id) {
      let keys = [];
      let vm = this;
      _.forEach(this.checks, function (value, key) {
        if (key.endsWith('_' + id)) {
          keys.push(key);
        }
      });
      _.forEach(keys, function (value, key) {
        vm.checks[value] = !vm.checks[value];
      });
    },
    getTrackers() {
      let that = this;
      axios.get('enumeration', {
        params: {
          type: 'tracker',
          active: true,
        },
      })
          .then(function (response) {
            that.trackers = response.data.data;
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    getStatuses() {
      let that = this;
      axios.get('enumeration', {
        params: {
          type: 'issue_status',
          active: true,
        },
      })
          .then(function (response) {
            that.statuses = response.data.data;
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    getPmRoles() {
      let that = this;
      axios.get('pm_role')
          .then(function (response) {
            that.pmRoles = response.data.data;
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    saveWorkflow() {
      let that = this;
      axios.post('workflow', {
        roles: this.choosedRoles,
        trackers: this.choosedTrackers,
        checks: this.checks,
      })
          .then(function (response) {
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    getTranitionsTable() {
      let that = this;
      axios.get('workflow', {
        params: {
          trackers: this.choosedTrackers,
          roles: this.choosedRoles,
        },
      })
          .then(function (response) {
            that.model = response.data.model;
            that.showTable = true;
            for (let status of that.statuses) {
              for (let stat of that.statuses) {
                that.$set(that.checks, status.id + '_' + stat.id, that.getValue(status.id, stat.id));
              }
            }
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    getValue(oldStatId, newStatId) {
      if (!this.model) {
        return null;
      }
      if (this.model[oldStatId]) {
        return this.model[oldStatId][newStatId];
      }
    },
  },
};
</script>
